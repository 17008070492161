import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import './raiz_de_amargura.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'

const badge = 'Análisis'
const title = 'Raíz de Amar\u00ADgura'
// const html_title = <span>Septua<span className='shy'></span>ginta</span>
const subtitle = 'Revisión hermenéutica de un concepto popular.'

export default function RaizDeAmargura() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            advertencias: {
                content: <><a href='https://ivpbooks.com/blog/hebrews-apostasy-and-perseverance.html'>https://ivpbooks.com/blog/hebrews-apostasy-and-perseverance.html.</a></>,
                // type: 'comment',
            },
            referencias: {
                content: <>Hay algunos ejemplos (30+) en <a href='https://www.wednesdayintheword.com/hebrews-ot-quotations/'>https://www.wednesdayintheword.com/hebrews-ot-quotations/</a>.</>,
                type: 'comment',
            },
        }

        return (
            <Box
                className='ArticleBody'
            >
                <Container>
                    <Typography>
                        Apartarse de Dios es una raíz de amargura que, brotando, puede contaminar a muchos. Abandonar la fe es lo que comunmente se llama “apostasía”. La Biblia enseña que la apostasía es como una raíz que producirá amargura ya que, rechazar a Dios habiendo recibido su gracia, conlleva graves consecuencias.
                    </Typography>
                    <Typography>
                        Por ejemplo, decir "la apostasía es raíz de amargura" sería como decir "la mentira es raíz de conflictos", en el sentido de que ambas frases tienen la misma construcción sintáctica.
                    </Typography>
                    <Typography>
                        Una de las interpretaciones populares de esta frase es que la raíz de amargura es algo que sucede cuando la amargura echa raíces en el corazón de alguien. Sin embargo, el texto bíblico parece referirse a algo muy distinto. De hecho, puede afirmarse que la raíz de amargura ni siquiera es un sentimiento sino una acción.
                    </Typography>
                    <Typography>
                        La Biblia solo contiene la frase "raíz de amargura" una vez, en el libro de Hebreos. Para estudiar este tema analizaremos un texto de Deuteronomio, veremos sus similitudes con el texto de Hebreos, y luego analizaremos el texto de Hebreos. Al finalizar compararemos las dos principales interpretaciones para evaluar los méritos de cada una.
                    </Typography>
                    <Typography variant='h2'>
                        Observaciones sobre Deuteronomio 29:16-21
                    </Typography>
                    <Typography>
                        En <BibleRef>Deuteronomio 29:16-21</BibleRef> se observa lo siguiente:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>El verbo “produce” indica que la raíz precede a la amargura.</span> En el v. 18 el texto indica que primero se forma la raíz y esta produce amargura (como en la naturaleza, donde la raíz crece antes que el tallo). No hay ambigüedad. Algunas interpretaciones afirman que la amargura llega primero y produce una raíz, pero esto no se ajustaría al texto.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>Se advierte explícitamente contra la apostasía.</span> En el v. 18, el autor menciona específicamente apartarse de Dios para servir a otros dioses.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>Se utiliza el ejemplo de un apostata.</span> El autor explica su advertencia imaginando a una persona que decide adorar a otros dioses:
                            </Typography>
                            <Typography component='ul'>
                                <Typography component='li'>
                                    <Typography>
                                        En esta situación hipotética la persona justifica su apostasía con el argumento de que servir a otros dioses (embriaguez) saciaría la necesidad de Dios (la sed).
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        En este escenario hipotético las naciones extranjeras llegarían a la conclusión de que el pecado habría sido que “fueron y sirvieron a dioses ajenos, y se inclinaron a ellos” (es decir, apostasía).
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        No se observa que la persona tenga rencor o amargura.
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>El ejemplo explica que la amargura es resultado del pecado.</span> La persona en este ejemplo hipotético se aparta de Dios y luego sufre amargura. Esto afirma nuevamente que la raíz llega antes que la amargura. Además, explica la razón de que sea así (que sería un castigo de Dios por la apostasía).
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>El contexto inmediato se centra en la apostasía.</span>
                            </Typography>
                            <Typography component='ul'>
                                <Typography component='li'>
                                    <Typography>
                                        <span className='InlineHeaderSecond'>El contexto previo consiste en exhortaciones a la obediencia.</span> P. ej.: "Guardaréis, pues, las palabras de este pacto, y las pondréis por obra, para que prosperéis en todo lo que hiciereis." <BibleRef parentheses>Deuteronomio 29:9</BibleRef>
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <span className='InlineHeaderSecond'>El contexto posterior consiste en exhortaciones a la obediencia.</span> P. ej.: "Las cosas secretas pertenecen a Jehová nuestro Dios; mas las reveladas son para nosotros y para nuestros hijos para siempre, para que cumplamos todas las palabras de esta ley." <BibleRef parentheses>Deuteronomio 29:29</BibleRef>
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>El ejemplo indica que no hay posibilidad de arrepentimiento.</span>
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h2'>
                        Paralelismos entre Hebreos y Deuteronomio
                    </Typography>
                    <Typography>
                        Hay varias características indicando que <BibleRef>Hebreos 12:14-17</BibleRef> es un paralelo de <BibleRef>Deuteronomio 29:16-27</BibleRef>. En la siguiente presentación podemos ver algunas de estas características:
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        '& .BlogQuote': {
                            fontStyle: 'normal',
                            fontSize: '0.91em',
                            fontVariationSettings: '"wght" 300',
                        }
                    }}>
                        <Typography variant='h4'>
                            Deuteronomio 29:16-21
                        </Typography>
                        <Typography>
                            <Quote>
<span className='RDA_NumVer'>16</span> Porque vosotros sabéis cómo habitamos en la tierra de Egipto, y cómo hemos pasado por en medio de las naciones por las cuales habéis pasado; <span className='RDA_NumVer'>17</span> y habéis visto sus <span className='RDA_Pecado'>abominaciones</span> y sus ídolos de madera y piedra, de plata y oro, que tienen consigo. <span className='RDA_NumVer'>18</span> <span className='RDA_Advertencia'><u>No sea que haya</u> entre vosotros varón o mujer, o familia o tribu,</span> <span className='RDA_Santidad'>cuyo corazón se aparte hoy de Jehová nuestro Dios,</span> <span className='RDA_Pecado'>para ir a servir a los dioses de esas naciones;</span> <span className='RDA_Raiz'><u>no sea que haya</u> en medio de vosotros <b>raíz que produzca hiel y ajenjo,</b></span> <span className='RDA_NumVer'>19</span> <span className='RDA_Advertencia'>y suceda que al oír las palabras de esta maldición, él se bendiga en su corazón, diciendo: Tendré paz, aunque ande en la dureza de mi corazón, <b>a fin de que con la embriaguez quite la sed</b>.</span> <span className='RDA_NumVer'>20</span> <span className='RDA_Perdon'><b>No querrá Jehová perdonarlo</b>, sino que entonces humeará la ira de Jehová y su celo sobre el tal hombre, y se asentará sobre él toda maldición escrita en este libro</span> [...]
                            </Quote>
                        </Typography>
                        <Typography variant='h4'>
                            Hebreos 12:14-17
                        </Typography>
                        <Typography>
                            <Quote>
<span className='RDA_NumVer'>14</span> Seguid la paz con todos, y la santidad, sin la cual nadie verá al Señor. <span className='RDA_NumVer'>15</span> <span className='RDA_Advertencia'>Mirad bien, <u>no sea que</u> alguno</span> <span className='RDA_Santidad'>deje de alcanzar la gracia de Dios;</span> <span className='RDA_Raiz'>que brotando alguna <b>raíz de amargura</b>, os estorbe, y por ella muchos sean contaminados;</span> <span className='RDA_NumVer'>16</span> <span className='RDA_Advertencia'><u>no sea que haya</u> algún <span className='RDA_Pecado'>fornicario</span>, o <span className='RDA_Pecado'>profano</span>, como Esaú, <b>que por una sola comida vendió su primogenitura</b>.</span> <span className='RDA_NumVer'>17</span> <span className='RDA_Perdon'>Porque ya sabéis que aun después, deseando heredar la bendición, fue desechado, y <b>no hubo oportunidad para el arrepentimiento</b>, aunque la procuró con lágrimas.</span>
                            </Quote>
                        </Typography>
                    </Box>
                    <Typography variant='h2'>
                        Observaciones de Hebreos 12:14-17
                    </Typography>
                    <Typography>
                        En <BibleRef>Hebreos 12:14-17</BibleRef> se observa lo siguiente:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>Probablemente hace referencia a Deuteronomio.</span> Hay al menos cuatro razónes para llegar a esta conclusión:
                            </Typography>
                            <Typography component='ul'>
                                <Typography component='li'>
                                    <Typography>
                                        <span className='InlineHeaderSecond'>Similitudes.</span> Hay suficientes similitudes con <BibleRef>Deuteronomio 29:16-21</BibleRef> para afirmar que el autor está referenciando dicho texto:
                                    </Typography>
                                    <Typography component='ul'>
                                        <Typography component='li'>
                                            <Typography>
                                                Ambos textos son advertencias
                                            </Typography>
                                        </Typography>
                                        <Typography component='li'>
                                            <Typography>
                                                Ambos textos se dirigen a toda la congregación.
                                            </Typography>
                                        </Typography>
                                        <Typography component='li'>
                                            <Typography>
                                                Ambos textos se encuentran en un contexto literario (previo y posterior) de exhortación a la obediencia.
                                            </Typography>
                                        </Typography>
                                        <Typography component='li'>
                                            <Typography>
                                                Ambos textos utilizan la frase “no sea que haya…” más de una vez.
                                            </Typography>
                                        </Typography>
                                        <Typography component='li'>
                                            <Typography>
                                                Ambos textos advierten contra una “raíz” que puede causar perjuicios.
                                            </Typography>
                                        </Typography>
                                        <Typography component='li'>
                                            <Typography>
                                                Ambos textos utilizan un ejemplo para ilustrar su advertencia.
                                            </Typography>
                                        </Typography>
                                        <Typography component='li'>
                                            <Typography>
                                                Ambos ejemplos comienzan con apostasía y terminan con amargura.
                                            </Typography>
                                        </Typography>
                                        <Typography component='li'>
                                            <Typography>
                                                Ambos textos señalan la dificultad para ser perdonado en tal caso.
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <span className='InlineHeaderSecond'>Contexto posterior.</span> A partir del v. 25 el autor enfatiza que deben prestar atención ya que la advertencia anterior (presumiblemente la de <BibleRef>Deuteronomio 29:18</BibleRef>) se cumplió y, por lo tanto, también se cumplirá esta que se funda en Jesucristo.
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <span className='InlineHeaderSecond'>Naturaleza comparativa y referencial de Hebreos.</span> Observamos que hebreos compara a Jesús con los ángeles y la Torah, con Moisés y la Tierra Prometida, con los sacerdotes y Melquisedec y con los sacrificios del Antiguo Testamento. También vemos que <Ref d={fn.referencias}>hace referencias al Antiguo Testamento docenas de veces.</Ref>
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>El contexto inmediato se centra en la apostasía.</span>
                            </Typography>
                            <Typography component='ul'>
                                <Typography component='li'>
                                    <Typography>
                                        <span className='InlineHeaderSecond'>El contexto previo consiste en exhortaciones a la obediencia.</span> P. ej.: "Por tanto, fortaleced las manos débiles y las rodillas que flaquean, y haced sendas derechas para vuestros pies, para que la pierna coja no se descoyunte, sino que se sane." <BibleRef parentheses>Hebreos 12:12-13</BibleRef>
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <span className='InlineHeaderSecond'>El contexto posterior consiste en exhortaciones a la obediencia.</span> P. ej.: "Mirad que no desechéis al que habla. Porque si no escaparon aquellos que desecharon al que los amonestaba en la tierra, mucho menos nosotros, si desecháremos al que amonesta desde los cielos." <BibleRef parentheses>Hebreos 12:25</BibleRef>
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Hebreos es un libro de naturaleza exhortativa.</span> El libro de Hebreos tiene al menos cinco grandes advertencias contra la apostasía <BibleRef parentheses><Ref d={fn.advertencias}>2:1–4; 3:7–4:13; 6:4–8; 10:26–31; 12:25–29</Ref></BibleRef> y el texto que analizamos forma parte de una de ellas, por lo que es más probable que su contenido corresponda a este tema (en lugar de, por ejemplo, al rencor).
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Esaú es un ejemplo de apostasía que causa amargura.</span> El autor se refiere a Esaú como "fornicario" y "profano" y destaca el hecho de que vendió su primogenitura por comida. En otras palabras, Esaú despreció lo santo y esa fue la raíz de la que brotó mucha amargura.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h2'>
                        Otras interpretaciones
                    </Typography>
                    <Typography>
                        La interpretación alternativa más popular de esta frase es que se refiere a una "amargura enraizada" y se relaciona tanto con la amargura como el rencor. Le expresión por si sola es ambigua y admite este significado. Además, tiene dos elementos a favor: 
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>La frase "seguid la paz con todos".</span> Esta expresión podría indicar un contexto relacionado con lo interpersonal, como la amargura o el rencor.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                            <span className='InlineHeader'>El ejemplo de Esaú</span>. Recordemos que Esaú tuvo rencor hacia su hermano. 
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        Respecto al primer punto podemos decir que hay más y mayores indicadores a favor de una temática relacionada con la apostasía.
                    </Typography>
                    <Typography>
                        Respecto al segundo punto observamos que el autor se refiere a Esaú como "fornicario" y "profano" (no como "rencoroso" y "amargado") y menciona el hecho de que vendió su primogenitura (no el hecho de que se peleó con su hermano). Siempre que sea posible debemos darle prioridad a lo que el autor puso en palabras por sobre nuestras conjeturas. En este caso, podemos conjeturar respecto al rencor de Esaú, o podemos aceptar que el escritor se expresa en contra de cómo Esaú despreció lo santo.
                    </Typography>
                    <Typography variant='h2'>
                        Conclusión
                    </Typography>
                    <Typography>
                        El texto de Deuteronomio se refiere inequivocamente a la apostasía e indica que esta es una raíz que producirá amargura. Hay suficiente evidencia para afirmar que el texto de Hebreos hace referencia a este texto y, por lo tanto, tiene el mismo sentido. También hay suficiente evidencia en el texto de Hebreos por si solo para llegar a esta conclusión.
                    </Typography>
                    <Notas footnotes={fn} />
                    <Author />
                </Container>
            </Box>
        )
    }

// Frases paralelas dentro de un mismo ver (improbable):
//     -	La frase clave en deut. Es una repetición de la frase anterior
// -	En hebreos, el autor cierra el párrafo hablando de “lágrimas” por lo que vemos ahí el ejemplo claro de amargura. Puede que el ejemplo sea un paralelo de la statement?
// otro (dicho, solo guardo por frases)
// -	No se puede favorecer lo que no está por sobre lo que está. Es más probable que el autor quiera decir lo que escribe, y no lo que calla. Es más probable que use el ejemplo de Esaú para ilustrar las dos cualidades que menciona (fornicario, profano) en lugar de una que no menciona.


    const body_css = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '0px',
                md: '1px',
            },
        },

    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Spline Sans Variable' display_font='Satoshi' badge={badge} title={title} subtitle={subtitle} Body={<Body />} other={body_css} classes={{wrapper: 'SoftBlogColors'}} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}
