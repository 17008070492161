import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ToolbarGeneralOne from '../../misc/ToolbarGeneralOne';
import BackgroundVideo from '../../resources/fluid.mp4'
import './Home.css'
import { useGlobal } from '../../contexts/GlobalContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet-async';

export const cards = [
    {
        title: 'Artículos',
        desc: 'Artículos curados o de autoría propia.',
        route: '/articulos',
        dark: '#0f2f3b',
        light: '#e3f8ff',
    },
    {
        title: 'Calendario de la crucifixión',
        desc: 'Días y eventos según el calendario judío.',
        route: '/calendariocrucifixion',
        dark: '#0f2f3b',
        light: '#e3f8ff',
    },
    {
        title: 'Cronología de los Evangelios',
        desc: 'Esquema cronológico de la historia relatada por los cuatro evangelistas.',
        route: '/cronologiaevangelios',
        dark: '#16165b',
        light: '#a8a8ff',
    },
    {
        title: 'Linea de Tiempo',
        desc: 'Historia humana organizada cronológicamente.',
        route: '/linea',
        dark: '',
        light: '',
    },
    {
        title: 'Sinopsis de los Evangelios',
        desc: 'Lectura paralela de los cuatro relatos bíblicos.',
        route: '/sinopsis',
        dark: '#210031',
        light: '#f6e6fe',
    },
]

export default function Home() {

    const { extended_theme } = useGlobal()
    const is_tall = useMediaQuery('(min-height:750px)')

    type iCardProps = {
        info: {
            title: string,
            desc: string,
            route: string,
            dark: string,
            light: string,
        }
    }
    const Card = (props: iCardProps) => {
        const { info } = props
        const { title, desc, route, dark, light } = info
        return (
            <Box
                sx={{
                    '& a': {
                        textDecoration: 'none !important',
                    },
                }}
            >
                <Link to={`${route}`}>
                    <Box
                        sx={{
                            maxWidth: '700px',
                            cursor: 'pointer',
                            backgroundColor: '#f7f7f8',
                            // mt: 6,
                            pt: {
                                us: '12px',
                                md: '19px',
                            },
                            pb: {
                                us: '16px',
                                md: '20px',
                            },
                            px: {
                                us: '8px',
                                md: '20px',
                            },
                            mb: '16px',
                            borderRadius: '11px',
                            mx: {
                                us: '8px',
                                md: 3,
                            },
                            border: '1px solid #f3f3f3',
                            ':hover': {
                                backgroundColor: 'white',
                                border: '1px solid lightgray'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                pb: {
                                    us: '4px',
                                    md: 1,
                                },
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: {
                                        us: '2.5rem',
                                        md: '3rem',
                                    },
                                    lineHeight: 1.2,
                                    fontVariationSettings: {
                                        us: '"wght" 800 !important',
                                        md: '"wght" 800 !important',
                                    },
                                    color: '#000'
                                }}
                            >
                                { title }&nbsp;→
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                lineHeight: 1.2,
                                fontSize: {
                                    us: '1.8rem',
                                    md: '2.1rem',
                                },
                            }}
                            >
                            { desc }
                        </Typography>
                    </Box>
                </Link>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                // height: '100vh',
                // width: '100vw',
                // display: is_tall ? 'flex' : 'block',
                display: 'flex',
                // pt: is_tall ? 0 : 10,
                // pt: is_tall ? 10 : 30,
                pt: {
                    us: '58px',
                    // sm: 12,
                    sm: '140px',
                },
                flexDirection: 'column',
                alignItems: 'center',
                // justifyContent: 'center',
                // backgroundColor:
            }}
        >
            <Helmet>
                <title>Bani Grisson</title>
            </Helmet>
            <ToolbarGeneralOne breadcrumbs={[{text: 'Inicio', path: ''}]} />
            <Box
                sx={{
                    // pb: {
                    //     us: '24px',
                    //     md: '0px',
                    // }
                    // pb: 6,
                    // mb: 6,
                    // mb: '150px',
                }}
            >
                <Box
                    sx={{
                        // borderBottom: '1px solid' + extended_theme.colors.lines,
                        // mt: 12,
                        mx: {
                            us: '6px',
                            md: 3,
                        },
                        mb: 3,
                    }}
                >
                    {/* <Typography
                        variant='h1'
                        sx={{
                            fontSize: {
                                us: '2.5rem',
                                md: '2.5rem',
                            },
                            // pt: {
                            //     us: 8,
                            //     md: 0,
                            // },
                            mb: 1,
                            fontVariationSettings: {
                                us: '"wght" 400',
                                md: '"wght" 300',
                            }
                        }}
                    >
                        Herramientas
                    </Typography> */}
                </Box>
                {
                    cards.map((v, i) => {
                        return (
                            <Card info={v} key={i} />
                        )
                    })
                }
            </Box>
            <Box
                sx={{
                    pb: {
                        // us: 10,
                        md: 0,
                    }
                }}
            >

            </Box>
        </Box>
    )
}
