import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'
import RecommendedBox from '../RecommendedBox'

const badge = 'Estudio'
const title = 'Guía de Dios'
const subtitle = 'Desafíos y ayudas más importantes para ser guiados por Dios.'

export default function GuiaDeDios() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            a: {
                content: 'Estos son solo algunos versículos que mencionan explícitamente al Espíritu Santo pero hay muchos otros que, aunque no lo nombran, puede afirmarse que se refieren a Él.',
                type: 'comment',
            },
        }

        return (
            <Box
                className='ArticleBody'
            >
                <Container>
                    <Typography>
                        Todos queremos respuestas, ayuda, y guía de Dios. Él tiene todas soluciones, y conoce todas las cosas.
                    </Typography>
                    <Typography>
                        En este tema extenso solo veremos un panorama general que sirva de marco para tomar seguridad y aprender más sobre el tema en el futuro.
                    </Typography>
                    <Typography>
                        Veamos las distintas maneras en que Dios puede hablarnos.
                    </Typography>
                    <Typography variant='h2'>
                        Formas en que Dios se comunica
                    </Typography>
                    <Typography variant='h3'>
                        Jesús
                    </Typography>
                    <Typography>
                        La primera forma en que Dios nos habla a es través de Jesús, que expresa perfectamente quién es el Padre. Miremos estos dos versículos:
                    </Typography>
                    {/* <Typography component='ul'> */}
                        {/* <Typography component='li'> */}
                        <Typography>
                            <Quote>
                                “Dios, habiendo hablado muchas veces y de muchas maneras en otro tiempo a los padres por los profetas, en estos postreros días nos ha hablado por el Hijo, a quien constituyó heredero de todo, y por quien asimismo hizo el universo;” (<BibleRef>Heb 1:1-2</BibleRef>).
                            </Quote>
                        </Typography>
                        {/* </Typography> */}
                        {/* <Typography component='li'> */}
                        <Typography>
                            <Quote>
                                “A Dios nadie le vio jamás; el unigénito Hijo, que está en el seno del Padre, él le ha dado a conocer.” (<BibleRef>Jn 1:18</BibleRef>).
                            </Quote>
                        </Typography>
                        {/* </Typography> */}
                    {/* </Typography> */}
                    <Typography variant='h3'>
                        Biblia
                    </Typography>
                    <Typography>
                        Sabemos que <Ref bible style>“tenemos también la palabra profética más segura, a la cual hacéis bien en estar atentos como a una antorcha que alumbra en lugar oscuro, hasta que el día esclarezca y el lucero de la mañana salga en vuestros corazones”</Ref> (<BibleRef>2 Pe 1:19</BibleRef>).
                    </Typography>
                    <Typography variant='h3'>
                        Iglesia
                    </Typography>
                    <Typography>
                        Dios utiliza la vida de otros creyentes, sus ministerios y dones para comunicarse con todas las personas.
                    </Typography>
                    <RecommendedBox>
                        <Typography variant='body2'>
                            Más del tema en <a
                                href='banigrisson.com/iglesia'
                            >este artículo sobre la iglesia ↗</a>
                        </Typography>
                    </RecommendedBox>
                    <Typography variant='h3'>
                        Espíritu Santo
                    </Typography>
                    <Typography>
                        El Espíritu Santo es parte de todas las formas en que Dios habla. Por ejemplo:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Cuando Dios habla a través de Jesús, el Espíritu es quien nos da testimonio de Él (<BibleRef>Jn 15:26</BibleRef>) y quién nos recuerda lo que Él dijo (<BibleRef>Jn 14:26</BibleRef>).
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Cuando Dios habla a través de la Biblia, el Espíritu es quien la inspiró (<BibleRef>2 Pe 1:21</BibleRef>).
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Cuando Dios habla a través de la iglesia, el Espíritu es quien la está guiando (<BibleRef>Hch 13:2; 15:28; 16:6-7; 20:22-23, 28; Ro 8:14; 1 Cor 2:10-11; 12:7-11</BibleRef>).
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        El Espíritu puede comunicarse con nosotros <Ref d={fn.a}>a través de nuestra memoria (<BibleRef>Jn 14:26</BibleRef>), convicciones (<BibleRef>Jn 16:8</BibleRef>), pensamientos y palabras (<BibleRef>1 Cor 2:12-13, Lc 12:11-12</BibleRef>), y a través de dones como ciencia, sabiduría y conocimiento (<BibleRef>1 Cor 12:4-11</BibleRef>).</Ref> Sabiendo que Dios es todopoderoso no hay razón para limitar al Espíritu; debemos admitir que Él puede comunicarse de innumerables maneras y es <Ref bible style>“Espíritu de sabiduría y de inteligencia, espíritu de consejo y de poder, espíritu de conocimiento y de temor de Jehová”</Ref> (<BibleRef>Is 11:2</BibleRef>).
                    </Typography>
                    <Typography variant='h2'>
                        Formas en que Dios no se comunica
                    </Typography>
                    <Typography>
                        Veamos también algunas maneras en que Dios no habla.
                    </Typography>
                    <Typography variant='h3'>
                        No se comunica como uno decide
                    </Typography>
                    <Typography>
                        Quienes llevan años siendo iglesia tal vez hayan escuchado (o dicho) alguna de estas frases:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Si aparece en oferta es porque Dios me guía a que lo compre.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                La primera persona que vea cuando entre a la iglesia, con esa Dios quiere que me case.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Si me ofrecen la oportunidad, es porque Dios me está invitando a hacerlo.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Estaba orando por este tema, así que esta debe ser la solución.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Voy a abrir la biblia en un versículo al azar y esa será la respuesta de Dios.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Si me dice que sí es de Dios.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        Estas frases pueden ser intentos de que Dios hable como uno quiere. Pero debemos recordar que no tenemos permiso ni capacidad para decidir cuándo y cómo Dios habla. No podemos apurar, forzar, o inventar su voz.
                    </Typography>
                    <Typography variant='h3'>
                        No se comunica como otros deciden
                    </Typography>
                    <Typography>
                        Así como uno puede cometer errores al intentar escuchar a Dios, los demás también. Como resultado, quienes llevan años siendo iglesia habrán escuchado (o dicho) alguna de estas frases:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Dios me dijo que tenés que darme una gran ofrenda.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Dios me dijo que tenemos que trabajar juntos en el ministerio.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Dios me dijo que nos tenemos que casar.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Dios me dijo que estas en pecado.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Dios me dijo que tenés que cambiar tal cosa.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        Es posible que en lugar de ser motivadas por el Espíritu Santo, estas frases estén motivadas por emociones, opiniones, etc. Dios puede hablarnos por medio de los demás, pero todos somos humanos y podemos equivocarnos, o hasta mentir.
                    </Typography>
                    <Typography>
                        Ante la duda, debemos esperar, ser humildes, y estar en comunión personal con el Espíritu. Apliquemos lo que enseñó el Apóstol Pablo: <Ref bible style>“examinadlo todo; retened lo bueno”</Ref> (<BibleRef>1 Ts 5:21</BibleRef>).
                    </Typography>
                    <Typography variant='h2'>
                        Interpretación
                    </Typography>
                    <Typography>
                        Además de evitar atribuirle a Dios palabras que no dijo, también debemos ser responsables en como interpretamos y entendemos lo que Dios efectivamente ha dicho. Por ejemplo, la Biblia es verdadera palabra de Dios, pero puede ser interpretada de formas completamente opuestas a lo que Él quiere decir. Lo mismo sucede con cualquier otra forma en que Dios se comunica.
                    </Typography>
                    <Typography variant='h2'>
                        Conclusiones
                    </Typography>
                    <Typography>
                        Muchos otros temas nos ayudarán a profundizar en cómo Dios nos guía: profecía, hermenéutica, cristología, pneumatología, eclesiología, etc.
                    </Typography>
                    <Typography>
                        Aprender a distinguir y escuchar lo que el Espíritu dice es una habilidad que se adquiere con práctica, humildad y tiempo.
                    </Typography>
                    <Typography>
                        Nuestras propias experiencias con Dios y la ayuda de personas sabias nos enseñarán a conocer lo que Dios quiere decirnos.
                    </Typography>
                    <Notas footnotes={fn} />
                    <Author />
                </Container>
            </Box>
        )
    }
    
    const body_css = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
    }

    const body_css_larken = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                xs: '-0.01em',
                md: '0em',
            },
        },
        body: {
            '& .NegativeListLi': {
                pb: '0px',
            },
            '& .YellowList': {
                pb: '0px',
            },
        }
    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Spline Sans Variable' display_font='Satoshi' badge={badge} title={title} subtitle={subtitle} Body={<Body />} other={body_css} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}
