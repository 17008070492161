import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Slide from '@mui/material/Slide'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { Link, useNavigate } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider'
import './ToolbarGeneralOne.css'
import { useGlobal } from '../contexts/GlobalContext'

type iToolbarBlogProps = {
    breadcrumbs?: {
        text: string,
        path: string,
    }[],
}

export default function ToolbarGeneralOne(props: iToolbarBlogProps) {

    const { breadcrumbs } = props

    const trigger = useScrollTrigger({ target: window ? window : undefined });
    const navigate = useNavigate()
    const { extended_theme } = useGlobal()

    const [ drawer, setDrawer ] = useState(false)

    type iDrawerItems = {
        text: string,
        link: string,
    }[]

    const first_drawer_items = [
        {
            text: 'Inicio',
            link: '',
        },
        {
            text: 'Contacto',
            link: 'contacto',
        },
        {
            text: 'Sobre mi',
            link: 'about',
        },
    ]

    const drawer_items: iDrawerItems = [
        {
            text: 'Sinopsis de los evangelios',
            link: 'sinopsis',
        },
        {
            text: 'Linea de tiempo',
            link: 'linea',
        },
        {
            text: 'Cronología de los evangelios',
            link: 'cronologiaevangelios',
        },
        {
            text: 'Calendario de la crucifixión',
            link: 'calendariocrucifixion',
        },
    ]

    return (
        <Box>
            <Drawer
                anchor='right'
                open={drawer}
                onClose={() => setDrawer(false)}
            >
                <Box>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            backdropFilter: 'none'
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="menu"
                            sx={{
                                mr: '-14px',
                                ml: '-8px',
                                color: 'text.primary'
                            }}
                            onClick={() => setDrawer(false)}
                            >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider sx={{ mb: 2, }} />
                    <Typography
                        sx={{
                            fontSize: {
                                us: '1.2rem',
                                md: '1.4rem',
                            },
                            letterSpacing: '2px',
                            opacity: 0.7,
                            fontVariationSettings: '"wght" 700 !important',
                            px: 2,
                            pb: 1,
                        }}
                    >
                        LINKS
                    </Typography>
                    <Divider sx={{ mt: 1, }} />
                    <List>
                        {
                            first_drawer_items && first_drawer_items.map((v, i) => {
                                return (
                                    <ListItem
                                        key={v.text}
                                        disablePadding
                                        component='a'
                                        href={'/' + v.link}
                                    >
                                        <ListItemButton>
                                            <ListItemText primary={v.text} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    <Divider />
                    <List>
                        {
                            drawer_items && drawer_items.map((v, i) => {
                                return (
                                    <ListItem
                                        key={v.text}
                                        disablePadding
                                        component='a'
                                        href={'/' + v.link}
                                    >
                                        <ListItemButton>
                                            <ListItemText primary={v.text} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Box>
            </Drawer>
            {/* <Slide appear={false} direction="down" in={!trigger}> */}
            <Box
                sx={{
                    
                }}
                >
                <AppBar
                    className={trigger ? 'HideAppbar' : 'ShowAppbar'}
                >
                    <Toolbar
                        className={trigger ? 'HideToolbar' : 'ShowToolbar'}
                        sx={{
                            justifyContent: 'space-between',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(9, 1fr)',
                            gridTemplateRows: '1fr',
                            borderBottom: '1px solid' + extended_theme.colors.lines,
                            // backgroundColor: '#f7f7f8',
                            '& a': {
                                textDecoration: 'none',
                            },
                            mt: '-1px',
                            // visibility: 'visible',
                            px: {
                                // us: '8px !important',
                                // md: '24px !important',
                            },
                        }}
                    >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gridColumn: '1 / span 8',
                            gridRow: '1',
                            zIndex: 2,
                            cursor: 'pointer !important',
                            '& .MuiTypography-root': {
                                fontSize: '2.2rem',
                                fontVariationSettings: '"wght" 600 !important'
                            },
                        }}
                        >
                        <Typography
                            onClick={() => navigate('/')}
                            sx={{
                                // fontVariationSettings: '"wght" 600 !important'
                            }}
                        >
                            B
                        </Typography>
                        <Typography
                            onClick={() => navigate('/')}
                        >
                            G
                        </Typography>
                        <Typography
                            // onClick={() => navigate('/')}
                            sx={{
                                borderLeft: '1px solid' + extended_theme.colors.lines,
                                // color: 'lightgray',
                                // opacity: 0.5,
                                color: '#00000045',
                                pl: {
                                    us: '8px',
                                    md: '14px',
                                },
                                ml: {
                                    us: '8px',
                                    md: '16px',
                                },
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {/* { name } */}
                            {
                                breadcrumbs?.map((v, i) => {
                                    return (
                                        <Link
                                            style={{
                                                color: 'inherit',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                textWrap: 'nowrap',
                                            }}
                                            to={'/' + v.path}
                                        >
                                            {v.text}{i < (breadcrumbs.length - 1) && ' › '}
                                        </Link>
                                    )
                                })
                            }
                        </Typography>
                        {/* {
                            !breadcrumbs ? null :
                                <Typography sx={{
                                    opacity: .3,
                                    textOverflow: 'hidden',
                                }}>
                                    { breadcrumbs }
                                </Typography>
                        } */}
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            justifyContent: 'end',
                            display: 'flex',
                            gridColumn: '1 / span 9',
                            gridRow: '1',
                        }}
                    >
                        <Stack
                            direction='row'
                            sx={{
                                alignItems: 'center',
                                '& .MuiTypography-root': {
                                    px: 3,
                                    fontVariationSettings: '"wght" 500',
                                    pt: 1,
                                    pb: '10px',
                                },
                                '& a': {
                                    display: {
                                        us: 'none',
                                        md: 'block'
                                    }
                                }
                            }}
                        >
                            {/* <Link to='/'>
                                <Typography
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '1.75rem'
                                    }}
                                    >
                                    Inicio
                                </Typography>
                            </Link>
                            <Link to='/contacto'>
                                <Typography
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '1.75rem'
                                    }}
                                >
                                    Contacto
                                </Typography>
                            </Link>
                            <Link to='/about'>
                                <Typography
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '1.75rem'
                                    }}
                                >
                                    Sobre mi
                                </Typography>
                            </Link> */}
                            <Box
                                sx={{
                                    py: 2,
                                    paddingLeft: '1px',
                                    backgroundColor: extended_theme.colors.lines,
                                    display: {
                                        us: 'none',
                                        md: 'block'
                                    }
                                }}
                            />
                            <Box
                                sx={{
                                    pl: 2,
                                    '& .MuiButtonBase-root': {
                                        paddingTop: '10px',
                                    },
                                }}
                            >
                                <IconButton
                                    size="large"
                                    aria-label="menu"
                                    sx={{
                                        mr: '-14px',
                                        ml: '-8px',
                                        color: 'text.primary'
                                    }}
                                    onClick={() => setDrawer(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            {/* </Slide> */}
        </Box>
    )
}
