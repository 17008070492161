import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'
import RecommendedBox from '../RecommendedBox'

const badge = 'Hermenéutica'
const title = 'Hipérbole didáctica'
const subtitle = 'Uso de la hipérbole con fines didácticos.'

export default function HiperboleDidactica() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            a: {
                content: '',
                type: 'comment',
            },
        }

        return (
            <Box
                className='ArticleBody'
                sx={{
                    'ol ol': {
                        pl: {
                            md: '2.2em',
                        },
                    }
                }}
            >
                <Container>
                    <Typography>
                        Consiste en exagerar de manera intencional una idea, situación, ejemplo, o argumento con fines educativos o didácticos.
                    </Typography>
                    <Typography>
                        La diferencia con la hipérbole retorica es que esta se emplea para enfatizar una idea, transmitir un sentimiento, o embellecer el discurso. Sumado a esto, la hipérbole didáctica busca provocar:
                    </Typography>
                    <Typography component='ol'
                        sx={{
                            '& b': {
                                fontVariationSettings: '"wght" 700',
                            },
                        }}                    
                    >
                        <Typography component='li'>
                            <Typography>
                                <b>Atención.</b> Como cualquier declaración polémica, atrevida, o ridícula llamará la atención del oyente.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <b>Pensamiento crítico.</b> Ya que la interpretación literal tiene connotaciones fuertes, contradictorias y/o impactantes el oyente evaluará lo cierto de la frase, sus méritos y fallos.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <b>Debate y participación.</b> Se fomenta la discusión e intercambio de ideas entre alumnos u oyentes ya que, como en cualquier tema polémico, habrá diversos puntos de vistas e interpretaciones.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <b>Memorización.</b> Al exagerar una idea de manera llamativa, crea un impacto emocional y sorpresa en el estudiante, lo que resulta más memorable que información presentada de manera monótona o convencional. Esto se debe a que esta activación emocional ayuda a consolidar los recuerdos, ya que los momentos emocionalmente intensos tienden a ser más memorables y duraderos.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <b>Admisión de ignorancia.</b> Es posible que mientras el oyente analiza esta idea controversial piense “realmente no entiendo lo que dice”, lo cuál implica una actitud de aprender.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <b>Autoevaluación.</b> Además de evaluar la idea, el individuo verá desafiadas sus convicciones personales y decidirá cuales interpretaciones son aceptables y cuáles no lo son (tanto desde el punto de vista intelectual como el volitivo). Especialmente aplicable a enseñanzas éticas y morales.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <b>Creación de ideas.</b> Al concluir su análisis, la persona habrá encontrado por si sola el punto de equilibrio entre extremos, exageraciones y absolutos. De esta forma, no está recibiendo información sino que está siendo guiada a través de un proceso de reflexión y análisis crítico, donde ella misma encuentra los argumentos que sostienen la enseñanza.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        Debe usarse con ciertos reparos ya que hacerlo de forma excesiva o imprudente puede llevar a la confusión o incluso la pérdida de credibilidad del orador, especialmente cuando se lo descontextualiza.
                    </Typography>
                    <Typography variant='h2'>
                        Indicadores de hipérbole
                    </Typography>
                    <Typography>
                        Si bien no hay remplazo para el sentido común, hay al menos cuatro indicadores de que una frase es hipérbole.
                        {/* que sirven para identificar  */}
                    </Typography>
                    <Typography variant='h3'>
                        #1: Imposibilidad literal
                    </Typography>
                    <Typography>
                        Cuando una frase es imposible de interpretar literalmente es posible que sea una hipérbole. Por ejemplo, no tendría sentido interpretar literalmente la frase “nos conocemos hace mil años”.
                    </Typography>
                    <Typography variant='h3'>
                        #2: Insensatez literal
                    </Typography>
                    <Typography>
                        Cuando el significado literal de una frase carece de lógica o sería falaz, es posible que sea hipérbole.
                    </Typography>
                    <Typography variant='h3'>
                        #3: Contradicción doctrinal
                    </Typography>
                    <Typography>
                        Cuando la interpretación literal de una frase contradice otra frase de su autor es posible que sea una hipérbole. Mientras mas confiable y coherente sea el autor más relevante es este indicador (por lo que es especialmente útil para los dichos de Jesús).
                    </Typography>
                    <Typography variant='h3'>
                        #4: Contradicción conductual
                    </Typography>
                    <Typography>
                        Cuando la interpretación literal de una frase se contradice con las acciones o conducta de su autor es posible que sea hipérbole. Mientras más íntegro sea el autor más relevante es este indicador (por lo que es especialmente útil para los dichos de Jesús).
                    </Typography>
                    <Typography variant='h2'>
                        Ejemplos bíblicos
                    </Typography>
                    <Typography variant='h3'>
                        Ejemplo #1
                    </Typography>
                    <Typography>
                        En el Sermón del Monte, Jesús dice:
                    </Typography>
                    <Typography>
                    <Quote>
                        <span className='VN'>1</span> Guardaos de hacer vuestra justicia delante de los hombres para ser vistos por ellos, de otra manera, no tenéis galardón de vuestro Padre que está en los Cielos. <span className='VN'>2</span> Cuando, pues, des limosna, no toques trompeta delante de ti, como hacen los hipócritas en las sinagogas y en las calles, para ser alabados por los hombres. De cierto os digo que ya están recibiendo toda su recompensa. <span className='VN'>3</span> Pero tú, cuando des limosna, no sepa tu izquierda qué hace tu derecha, <span className='VN'>4</span> para que así tu limosna sea en secreto, y tu Padre, que ve en lo secreto, te recompensará. <BibleRef style parentheses>Mt 6:1-4, BTX4</BibleRef>
                    </Quote>
                    </Typography>
                    <Typography>
                        Esta frase no se puede interpretar literalmente porque las manos no pueden “saber” (Indicador #1). Por otro lado, interpretarla como hipérbole resulta en un significado claro y simple.
                    </Typography>
                    <Typography variant='h3'>
                        Ejemplo #2
                    </Typography>
                    <Typography>
                        Otro fragmento del mismo Sermón dice:
                    </Typography>
                    <Typography>
                        <Quote>
                            <span className='VN'>5</span> Y cuando oréis, no seáis como los hipócritas; porque a ellos les gusta ponerse en pie y orar en las sinagogas y en las esquinas de las calles, para ser vistos por los hombres. En verdad os digo que ya han recibido su recompensa. <span className='VN'>6</span> Pero tú, cuando ores, entra en tu aposento, y cuando hayas cerrado la puerta, ora a tu Padre que está en secreto, y tu Padre, que ve en lo secreto, te recompensará. <BibleRef style parentheses>Mateo 6:5-6, LBLA</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        Consideremos lo siguiente:
                    </Typography>
                    <Typography component='ol'>
                        <Typography component='li'>
                            <Typography>
                                Jesús realizó oraciones (privadas) en público varias veces, incluso con intención de que lo escucharan <BibleRef style parentheses>Jn 11:41-42</BibleRef>.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Teniendo en cuenta el punto anterior, en esta frase observamos el Indicador #4, es decir:
                            </Typography>
                            <Typography component='ol'>
                                <Typography  component='li'>
                                    <Typography>
                                        Jesús fue íntegro.
                                    </Typography>
                                </Typography>
                                <Typography  component='li'>
                                    <Typography>
                                        Interpretar esta frase literalmente significaría que Jesús era hipócrita.
                                    </Typography>
                                </Typography>
                                <Typography  component='li'>
                                    <Typography>
                                        Por lo tanto, esta frase no puede interpretarse literalmente.
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Interpretarla como hipérbole nos provee una interpretación razonable y cumple con las características definidas al principio. 
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h3'>
                        Ejemplo #3
                    </Typography>
                    <Typography>
                        Hay otro ejemplo en el mismo sermón cuando se refiriere al tema del adulterio:
                    </Typography>
                    <Typography>
                        <Quote>
                            <span className='VN'>28</span> Pero yo os digo que cualquiera que mira a una mujer para codiciarla, ya adulteró con ella en su corazón. <span className='VN'>29</span> Y si tu ojo derecho te es ocasión de pecar, arráncalo y échalo de ti; porque te es mejor que se pierda uno de tus miembros, y no que todo tu cuerpo sea arrojado al infierno. <span className='VN'>30</span> Y si tu mano derecha te es ocasión de pecar, córtala y échala de ti; porque te es mejor que se pierda uno de tus miembros, y no que todo tu cuerpo vaya al infierno. <BibleRef style parentheses>Mateo 5:29-30, LBLA</BibleRef>
                        </Quote>
                    </Typography>
                    <Typography>
                        Consideremos lo siguiente:
                    </Typography>
                    <Typography component='ol'>
                        <Typography component='li'>
                            <Typography>
                                Según Jesús el pecado proviene del corazón, no de los ojos o las manos:
                            </Typography>
                            <Typography>
                                <Quote>
                                <span className='VN'>18</span> Pero lo que sale de la boca, del corazón sale; y esto contamina al hombre. 19 Porque del corazón salen los malos pensamientos, los homicidios, los adulterios, las fornicaciones, los hurtos, los falsos testimonios, las blasfemias. <span className='VN'>20</span> Estas cosas son las que contaminan al hombre; pero el comer con las manos sin lavar no contamina al hombre. <BibleRef style parentheses>Mateo 15:18-20</BibleRef>
                                </Quote>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                De acuerdo con el punto (1), sacarse los ojos o las manos no es una solución al adulterio o al pecado en general. Por lo tanto encontramos el Indicador #3, es decir:
                            </Typography>
                            <Typography component='ol'>
                                <Typography component='li'>
                                    <Typography>
                                        Jesús era una persona lógica.
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        Argumentar que quitarse un ojo evita el pecado es ilógico, por lo que interpretar esta frase literalmente indicaría que Jesús estaba siendo ilógico.
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        Por lo tanto, esta frase no puede interpretarse literalmente.
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Interpretarla como hipérbole nos provee una interpretación razonable y cumple con las características definidas al principio.
                            </Typography>
                        </Typography>
                        <Typography>
                            La enseñanza de Jesús consiste en que el adulterio no es meramente la acción externa sino también el camino interno que conduce a ella. A esto suma un dicho hiperbólico para enseñar que no solamente hay que resistirse a andar en ese camino sino incluso tomar medidas para prevenirlo.
                        </Typography>
                    {/* </Typography> */}
                    </Typography>
                    {/* <Notas footnotes={fn} /> */}
                    <Author />
                </Container>
            </Box>
        )
    }
    
    const body_css = {
        title: {
            fontSize: {
                us: '8.5rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
    }

    const body_css_larken = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                xs: '-0.01em',
                md: '0em',
            },
        },
        body: {
            '& .NegativeListLi': {
                pb: '0px',
            },
            '& .YellowList': {
                pb: '0px',
            },
        }
    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Spline Sans Variable' display_font="Satoshi, sans" badge={badge} title={title} subtitle={subtitle} Body={<Body />} other={body_css} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}
