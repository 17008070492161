import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'

const badge = 'Introducción'
const title = 'Sep\u00ADtua\u00ADgin\u00ADta'
// const html_title = <span>Septua<span className='shy'></span>ginta</span>
const subtitle = 'Características generales e importancia para la hermenéutica.'

export default function Septuagint() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            early: {
                content: <>Early, J. (2015). A History of Christianity: An Introductory Survey. B&H Publishing Group.</>,
                // type: 'comment',
            },
            knibb: {
                content: <>Dines, J. M., & Knibb, M. A. (2004). The Septuagint (1.a ed.) [Libro electrónico]. T&T.</>,
                // type: 'comment',
            },
        }

        return (
            <Box
                className='ArticleBody'
            >
                <Container>
                    <Typography variant='h2'>
                        Introducción
                    </Typography>
                    <Typography variant='h3'>
                        Generalidades
                    </Typography>
                    <Typography>
                        La Septuaginta es la traducción al griego más antigua del Antiguo Testamento, que originalmente fue escrito en hebreo y arameo.
                    </Typography>
                    <Typography variant='h3'>
                        Origen
                    </Typography>
                    <Typography>
                        Para el siglo III a. C. el Imperio Romano había conquistado todo el mundo conocido y el griego se había convertido en lengua franca, facilitando el comercio y la difusión de la filosofía, ciencia y literatura. La prominencia del idioma griego también presentaba dificultades. Si una persona quería tener una buena educación o ser importante en la política o la sociedad necesitaba hablar griego fluidamente. Early explica:
                    </Typography>
                    <Typography>
                        <Quote>
                            <Ref d={fn.early}>Muchos judíos helenísticos se hicieron tan griegos que olvidaron el idioma hebreo. Esto era un problema importante ya que Jehová inspiró a los profetas judíos a escribir las Escrituras en Hebreo. Hasta entonces, nadie había traducido la Biblia Hebrea a otro idioma, y los líderes judíos enfrentaban el dilema de permitir que el judaísmo muriera o permitir que las Escrituras fueran traducidas al Griego.</Ref>
                        </Quote>
                    </Typography>
                    <Typography>
                        Luego de mucha deliberación, las autoridades correspondientes autorizaron la traducción. El nombre Septuaginta es latín para “setenta” y se refiere a la tradición judía de que setenta (o setenta y dos) ancianos judíos realizaron la traducción. Por esta razón, frecuentemente nos referimos a la Septuaginta como LXX (el numeral romano para setenta).
                    </Typography>
                    <Typography>
                        <Ref d={fn.knibb}>Los manuscritos más antiguos que tenemos se encontraron entre los Papiros del Mar Muerto y datan del siglo II a. C. Estos ya muestran señales de edición, por lo tanto la traducción inicial sería anterior a esa fecha</Ref>.
                    </Typography>
                    <Typography variant='h2'>
                        Utilidad
                    </Typography>
                    <Typography>
                        La Septuaginta tiene una doble utilidad hermenéutica.
                    </Typography>
                    <Typography variant='h3'>
                        Ayuda a entender el A.T
                    </Typography>
                    <Typography>
                        La LXX nos ayuda a entender los escritos del Antiguo Pacto por dos factores.
                    </Typography>
                    <Typography variant='h4'>
                        Mayor precisión del idioma Griego
                    </Typography>
                    <Typography>
                        El griego es un idioma más preciso que el Hebreo por dos razones:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Tiene más vocabulario
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Provee más información, ya que tiene más tiempos verbales, así como casos y voces.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h4'>
                        Decisiones de los traductores
                    </Typography>
                    <Typography>
                        Una de las preguntas claves cuando traducimos es “¿Cómo se interpretaba tal palabra o expresión en ese momento?”. Usualmente para responderla recurrimos a fuentes históricas de la época, pero aún así la información suele ser limitada y no se logra certeza sobre el sentido del texto.
                    </Typography>
                    <Typography>
                        La Septuaginta puede ayudarnos proveyendo un poco más de información. Claramente los traductores estaban familiarizados con el idioma y la cultura hebrea porque formaban parte de ella. Por lo tanto, estaban más cerca que nosotros de entender el significado original de una palabra o expresión. Cuando eligen cierta palabra o expresión griega como la representación más exacta del texto original podemos aprender de esta decisión. Podemos usar la información que provee el idioma griego y también los documentos históricos que nos informan sobre él como otro punto de referencia que permite lograr una traducción más precisa y acercarnos a la mejor interpretación.
                    </Typography>
                    <Typography variant='h3'>
                        Ayuda a entender el N.T
                    </Typography>
                    <Typography>
                        Así como usamos la traducción griega para entender mejor el texto hebreo también podemos usarlos a la inversa para lograr mayor claridad especialmente en términos teológicos que no son tan comunes en materiales de referencia fuera del cristianismo.
                    </Typography>
                    <Typography>
                        Recordemos que no existen diccionarios con la definición de cada palabra. Debemos deducir su significado a través del uso y el análisis morfológico. Mientras más ejemplos de uso tenemos, más fácil es determinar su significado.
                    </Typography>
                    <Notas footnotes={fn} />
                    <Author />
                </Container>
            </Box>
        )
    }

    const body_css = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
    }

    const body_css_larken = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                xs: '-0.01em',
                md: '0em',
            },
        },
        body: {
            '& .NegativeListLi': {
                pb: '0px',
            },
            '& .YellowList': {
                pb: '0px',
            },
        }
    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Spline Sans Variable' display_font='Satoshi' badge={badge} title={title} subtitle={subtitle} Body={<Body />} other={body_css} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}
