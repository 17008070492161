import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'

const badge = 'Estudio'
const title = 'Iglesia'
const subtitle = 'Características generales e importancia para el creyente.'

export default function Iglesia() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            bghdi: {
                type: 'comment',
                content: <>P. ej.: <BibleRef>Hch 9:31, Ef 1:22-23, 5:25-27, Mt 16:18, Col 1:18, Ap 19:7-9, Jn 17:21-23.</BibleRef></>
            },
            bshxi: {
                type: 'comment',
                content: <>“Iglesia local” también puede referirse a todos los creyentes en una ciudad, aunque este uso es menos común. Por ejemplo, en Apocalipsis se hace referencia a “la iglesia” de tal o cual ciudad. Es probable que hubiera más de un grupo en cada ciudad, pero se los menciona con la palabra “iglesia” en singular.</>
            },
            ccchsj: {
                type: 'comment',
                content: <>Tomemos como ejemplo el don “sabiduría”. Este puede referirse a <b>(1)</b> una sensatez y madurez general (lo que sería un don más natural) o a <b>(2)</b> cuando el Espíritu Santo actúa de forma milagrosa dando sabiduría para resolver un problema o situación específica (lo que sería un don más sobrenatural).</>
            },
            sndpa: {
                type: 'comment',
                content: <>Para simplificar, se considera la definición eclesiástica contemporánea del término y se ignoran otros contextos.</>
            },
            spdpa: {
                type: 'comment',
                content: <>Aunque no siempre se puede remplazar las palabras “servicio” o “trabajo” por “ministerio”.</>
            },
            spgpa: {
                type: 'comment',
                content: <>Cobrar por servir/trabajar/ministrar en la iglesia está permitido por la Biblia (y en distintas situaciones hasta recomendado). En el caso de la iglesia Casa de Oración y Milagros nadie es remunerado sino que cada uno tiene sus respectivos trabajos y/o jubilaciones.</>
            },
            ndaa: {
                type: 'comment',
                content: <><BibleRef>1 Ti 4:12, 2 Ti 3:10-14, Ti 2:7, 1 Pe 5:3, 1 Te 1:6, 1 Co 11:1</BibleRef>.</>
            },
            sfbad: {
                type: 'comment',
                content: <>Lista completa: <a href='https://banigrisson.notion.site/unos-a-otros-40466e8c4f1d4fcf90351fe0f50ae62f' target='_blank'>https://banigrisson.notion.site/unos-a-otros-40466e8c4f1d4fcf90351fe0f50ae62f</a>.</>
            },
            sdjnp: {
                type: 'comment',
                content: <>En Hechos 2-4 vemos una iglesia ideal: se congregaban todos los días, todo el que podía trabajar lo hacía (y se ayudaba al que aun así tuviera necesidad) por lo que no había pobres, había milagros y conversiones continuamente, etc.</>
            },
            oncnd: {
                type: 'comment',
                content: <>Este es un tema interesante para desarrollar, pero sería para una clase de teología pastoral.</>
            },
            amsdnf: {
                type: 'citation',
                content: <>Hechos 2:46</>
            },
        }

        return (
            <Box
                className='ArticleBody'
            >
                <Container>
                    <Typography>
                        Esta palabra tiene tres significados principales:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Iglesia de Cristo</span>: el conjunto de todos los cristianos del mundo.<Ref d={fn.bghdi}></Ref>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Iglesia local</span>: cada grupo de cristianos dentro de una ciudad.<Ref d={fn.bshxi}></Ref>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>Iglesia, templo, o edificio</span>: el lugar donde se reúne la congregación. Aunque no es un uso propio de la palabra, es común y aceptado.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        En este breve estudio nos enfocaremos en algunos aspectos relacionados con el segundo significado.
                    </Typography>
                    <Typography variant='h2'>Un lugar imperfecto</Typography>
                    <Typography>
                        Dios es perfecto, pero la iglesia perfecta no existe: todos los miembros de esta son seres humanos con errores e imperfecciones, como todas las personas. Dicho esto, se espera que los creyentes tengan un mayor compromiso con la verdad, honestidad y buen trato que cualquier otro grupo de personas. 
                    </Typography>
                    <Typography>
                        Ambas facetas deben considerarse:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            Por un lado, debemos recordar que nuestra fe esta puesta en Dios y no en las personas. Por lo que debemos permanecer confiando en Él aunque alguien nos decepcione.
                        </Typography>
                        <Typography component='li'>
                            Por otro lado, es importante saber que en la iglesia debemos ser tratados con dignidad y respeto, especialmente por quienes sean referentes. No hay excusas o justificaciones para el maltrato, mentiras, o manipulaciones.
                        </Typography>
                    </Typography>
                    <Typography>
                        Teniendo esto en cuenta, consideremos que a veces estaremos en desacuerdo con algo que hacen o dicen los demás. Puede ser algo que debe cambiar, o puede que nosotros estemos equivocados, o un poco de ambos. Debemos ser pacientes, humildes, y sabios.
                    </Typography>
                    </Container>
                    <Box
                    sx={{
                        width: {
                            xs: '100%',
                            md: '100%',
                        },
                        margin: 'auto',

                    }}
                >
                    <img
                        className='IMGTEST'    
                        src='https://i.postimg.cc/sxjGbmSN/Davinci-last-supper-6.png'></img>
                    </Box>
                    <Container>
                    <Typography variant='h2'>
                        Dones
                    </Typography>
                    <Typography>
                        La palabra “don” significa “regalo” y suele hacer referencia a una capacidad que alguien tiene (considerada un regalo de Dios). La enseñanza más extensa sobre este tema se encuentra en <BibleRef>1 Corintios 12-14</BibleRef>.
                    </Typography>
                    <Typography>
                        Algunos dones podrían considerarse más <i>naturales</i>, por ejemplo oratoria, enseñanza, música, servicio, liderazgo, generosidad, administración, etc. Otros dones podrían considerarse más <i>sobrenaturales</i>, por ejemplo palabra de ciencia, interpretación de lenguas, profecía, etc. También hay algunas palabras que pueden referirse a dos dones distintos, por ejemplo sabiduría, conocimiento, milagros, fe, discernimiento, etc.<Ref d={fn.ccchsj}></Ref> Todos estos dones que se pueden compartir tienen como fin que los creyentes se edifiquen unos a otros (<BibleRef>1 Co 14:12, 12:7, 1 Pe 4:10, Ef 4:12</BibleRef>).
                    </Typography>
                    <Typography>
                        Los distintos dones y sus características individuales merecen su propio estudio; en esta ocasión el énfasis se halla en lo siguiente: para ser edificados por personas con estos dones debemos congregarnos.
                    </Typography>
                    <Typography variant='h2'>
                        Ministerios
                    </Typography>
                    <Typography>
                        A diferencia de otros ámbitos, en la iglesia el término “ministerio” no se refiere a una institución o cargo específico<Ref d={fn.sndpa}></Ref>, sino que es remplazable por la palabra “servicio” o “trabajo”.<Ref d={fn.spdpa}></Ref> Por ejemplo, si alguien dice “tal persona tiene capacidad para el ministerio de la enseñanza” significa que una persona tiene cualidades para enseñar a otros dentro de la iglesia.
                    </Typography>
                    <Typography>
                        Tal vez algunos de los ministerios más conocidos son pastor, maestro, o evangelista, pero en la iglesia hay tantos ministerios como formas de servir a los demás.
                    </Typography>
                    <Typography>
                        Es comparable con la vida cotidiana, donde hay personas especializadas que proveen servicios muy importantes (médicos, ingenieros, etc.) y son irremplazables.<Ref d={fn.spgpa} />
                    </Typography>
                    <Typography>
                        En definitiva, en la iglesia hay personas llenas del Espíritu Santo que pueden marcar un antes y un después en nuestra vida. Si queremos ser edificados por ellos debemos congregarnos.
                    </Typography>
                    <Typography variant='h2'>
                        Ejemplos
                    </Typography>
                    <Typography>
                        Cualquier habilidad es más fácil de aprender acompañado de alguien que sabe del tema. Muchas veces se aprende más de las acciones que con palabras.
                    </Typography>
                    <Typography>
                        En la iglesia hay personas con mucha fe, sabiduría, paz, etc. que pueden inspirarnos o “contagiarnos” esas virtudes.<Ref d={fn.ndaa}/>
                    </Typography>
                    <Typography variant='h2'>
                        Amistades
                    </Typography>
                    <Typography>
                        Una de las oportunidades más valiosas que nos provee la iglesia es la de encontrar personas que comparten nuestros valores y formar amistades.
                    </Typography>
                    <Typography variant='h2'>
                        Testimonios
                    </Typography>
                    <Typography>
                        Escuchar las experiencias de otros puede poner en perspectiva nuestra situación, movernos a ser fieles a Dios, y tener fe en que Él también lo será, aunque la situación sea difícil.
                    </Typography>
                    <Typography variant='h2'>
                        Enfoque y soporte
                    </Typography>
                    <Typography>
                        Todos tenemos altos y bajos. En la iglesia podemos encontrar alguien que nos recuerde una promesa de Dios que habíamos olvidado, que nos señale un problema que no habíamos notado, que nos comparta una solución que no habíamos considerado, etc., en el momento que más lo necesitemos.
                    </Typography>
                    <Typography variant='h2'>
                        Unos a otros
                    </Typography>
                    <Typography>
                        La frase “unos a otros” se repite sesenta veces en el Nuevo Pacto. Estos son algunos ejemplos: sean afectuosos unos con otros (<BibleRef>1 Pe 3:8, Ro 12:10</BibleRef>), sosténganse unos a otros (<BibleRef>Col 3:13</BibleRef>), lleven los unos las cargas de los otros (<BibleRef>Gál 6:2</BibleRef>), sean amables unos con otros (<BibleRef>Ef 4:32</BibleRef>), perdónense unos a otros (<BibleRef>Ef 4:32</BibleRef>), sean obedientes unos a otros (<BibleRef>Ef 5:21</BibleRef>), sean humildes unos con otros (<BibleRef>Fil 2:3, 1 Pe 5:5</BibleRef>), enséñense y corríjanse unos a otros (<BibleRef>Col 3:16</BibleRef>), aliéntense unos a otros (<BibleRef>1 Tes 5:11</BibleRef>), edifíquense unos a otros (<BibleRef>1 Tes 5:11</BibleRef>), oren unos por otros (<BibleRef>Stg 5:16</BibleRef>), sírvanse unos a otros (<BibleRef>1 Pe 4:10</BibleRef>), sean pacientes unos con otros (<BibleRef>Ef 4:2</BibleRef>).<Ref d={fn.sfbad}/>
                    </Typography>
                    <Typography>
                        El que más se repite es “ámense unos a otros” (<BibleRef>Ro 13:8, 1 Tes 4:9, 1 Pe 4:8, 1 Jn 3:11, 4:7, 11, 12</BibleRef>) y fue el último mandamiento de Jesús (<BibleRef>Jn 13:34-35, 15:12, 17, 1 Jn 3:23, 2 Jn 5</BibleRef>).
                    </Typography>
                    <Typography>
                        También hay algunos textos señalando acciones opuestas: no mentirse unos a otros (<BibleRef>Col 3:9</BibleRef>), no hablar mal unos de otros (<BibleRef>Stg 4:11</BibleRef>), no quejarse unos de otros (<BibleRef>Stg 5:9</BibleRef>), no ser vanagloriosos, provocándonos y envidiándonos unos a otros (<BibleRef>Gál 5:26</BibleRef>).
                    </Typography>
                    <Typography variant='h2'>
                        Servicio
                    </Typography>
                    <Typography>
                        La iglesia no es un lugar donde vamos solo a recibir sino también a dar, servir y ministrar. Ser de bendición para los demás puede ser muy satisfactorio.
                    </Typography>
                    <Typography>
                        También suele ser muy edificante; muchas veces se aprende más haciendo y enseñando que solo escuchando.
                    </Typography>
                    <Typography variant='h2'>
                        Frecuencia
                    </Typography>
                    <Typography>
                        “¿Con qué frecuencia debemos congregarnos?”. Podemos encontrar tres respuestas a esta pregunta: el ideal, el mínimo, y el equilibrio.
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>El ideal</span>. <BibleRef>Hechos 2:46</BibleRef> dice: <Ref style bible d={fn.amsdnf}>“Y perseverando unánimes cada día en el templo, y partiendo el pan en las casas, comían juntos con alegría y sencillez de corazón”</Ref>. Este versículo y su contexto señalan que lo ideal es congregarse diariamente.<Ref d={fn.sdjnp}/>
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>El mínimo</span>. Considerando el ideal, en la actualidad el consenso general es que uno necesita asistir al menos a una reunión por semana.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                <span className='InlineHeader'>El equilibrio</span>. Un punto intermedio sería un promedio de dos o tres reuniones por semana, que es lo mínimo para que una persona se mantenga fortalecida espiritualmente. Por esta razón, los voluntarios de la iglesia (ministros, obreros, etc.) se esfuerzan en organizar al menos esta cantidad de reuniones para cada grupo de personas.<Ref d={fn.oncnd}/>
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h2'>
                        Conclusiones
                    </Typography>
                    <Typography>
                        Hay cientos de temas para hablar sobre la iglesia: organización, disciplina, misiones, supervisión, responsabilidad, compromiso, conflictos, evangelismo, doctrina, denominación, etc.
                    </Typography>
                    <Typography>
                        El versículo cardinal sobre la vida congregacional es Hebreos 10:24-25:
                    </Typography>
                    <Typography>
                        <Quote>
                            Y considerémonos unos a otros para estimularnos al amor y a las buenas obras; no dejando de congregarnos, como algunos tienen por costumbre, sino exhortándonos; y tanto más, cuanto veis que aquel día se acerca.
                        </Quote>
                    </Typography>
                    <Typography>
                        Además de las mencionadas, hay muchas otras maneras en que Dios utiliza la iglesia para bendecir nuestra vida. Si queremos experimentar todo lo que Dios tiene para nosotros debemos congregarnos y esforzarnos en bendecirnos unos a otros. 
                    </Typography>
                    <Notas footnotes={fn} />
                    <Author />
                </Container>
            </Box>
        )
    }
    
    const body_css = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
    }

    const body_css_larken = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                xs: '-0.01em',
                md: '0em',
            },
        },
        body: {
            '& .NegativeListLi': {
                pb: '0px',
            },
            '& .YellowList': {
                pb: '0px',
            },
        }
    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Spline Sans Variable' display_font='Satoshi' badge={badge} title={title} subtitle={subtitle} Body={<Body />} other={body_css} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}
