import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useGlobal } from '../../../contexts/GlobalContext'

import { iBlogTestProps } from './BlogStyleA'
// import '../../../../src/resources/Font - Graphik/stylesheet.css'
// import '../../../../src/resources/Font - General Sans/css/general-sans.css'
import '../../../../src/resources/Font - Spline Sans/css/stylesheet.css'
import '../../../../src/resources/Font - Recia/css/recia.css'
import { fontshare_colors } from '../../../themes'
// import '../../../../src/resources/Font - Public Sans Web/css/public-sans.css'
// import '../../../../src/resources/Font - Mona Sans/monasans.css'



export default function BlogStyleA(props: iBlogTestProps) {

    const {
        passthough_badge, badge, passthough_title, title, passthough_subtitle, subtitle, passthough_body, Body, text_font, display_font, other, classes
    } = props

    const { extended_theme } = useGlobal()

    return (
        <Box
            className={classes?.wrapper}
            sx={{
                '& .MuiContainer-root': {
                    maxWidth: '720px !important'
                }
            }}
        >
            <Container>
                <Typography
                    sx={{
                        pt: {
                            us: 12,
                            md: 19,
                        },
                        // opacity: 0.8,
                        color: '#181818',
                        fontVariationSettings: '"wght" 485',
                        fontSize: '2rem',
                        // fontFamily: extended_theme.font_display
                        fontFamily: `${text_font}, system-ui !important`,
                        // fontFeatureSettings: '"ss00" !important',
                        // fontFeatureSettings: '"ss05" !important',
                    }}
                >
                    { badge }
                </Typography>
                <Typography
                    variant='h1'
                    sx={{
                        fontWeight: 500,
                        fontSize: {
                            us: '5rem',
                            md: '6rem',
                        },
                        fontVariationSettings: '"wght" 550',
                        letterSpacing: '-2.5px',
                    lineHeight: '1em',
                    fontFamily: `${display_font}, 'system-ui' !important`,
                    // backgroundImage: 'linear-gradient(90.71deg, rgb(138, 86, 86) 0%, rgb(72, 24, 72)90%), none',
                    // backgroundImage: 'linear-gradient(90.71deg, rgb(86, 99, 138) 0%, rgb(44, 24, 72) 90%), none',
                    // backgroundClip: 'text',
                    // color: 'transparent',
                    // paddingBottom: '20px',
                    ml: '-0.02em',
                    ...other?.title
                }}
            >
                { title }
            </Typography>
            <Typography
                variant='subtitle1'
                sx={{
                    fontSize: {
                        us: '2.2rem',
                        md: '2.4rem'
                    },
                    fontFamily: `${text_font} !important`,
                    // fontFeatureSettings: '"ss00" !important',
                    pb: 6,
                    pt: 2,
                    lineHeight: 1.4,
                    fontVariationSettings: '"wght" 430',
                    opacity: 0.9,
                    letterSpacing: '-0.4px',
                }}
            >
                { subtitle }
            </Typography>
            </Container>
            <Box
                sx={{
                    '& .MuiTypography-body1': {
                        fontFamily: `${text_font}`,
                        fontVariationSettings: '"wght" 350',
                        fontFeatureSettings: '"ss02" !important', // for Spline, not ss03 as satoshi
                        // letterSpacing: '-0.006em',
                        lineHeight: 1.55,
                        fontSize: {
                            us: '1.8rem',
                            md: '2.05rem'
                        },
                        // hyphens: 'auto',
                        textAlign: {
                            xs: 'auto',
                            // md: 'justify'
                        },
                        pb: '0.6em',
                    },
                    // '& .HighlighterBlog': {
                    //     textDecorationColor: fontshare_colors.yellow_medium + ' !important',
                    // },
                    // '& .BlogQuote': {
                    //     borderImage: 'none',
                    //     borderColor: fontshare_colors.blue_medium + ' !important'
                    // },
                    '& .NegativeListLi': {
                        fontVariationSettings: '"wght" 800',
                    },
                    '& .MuiTypography-h2': {
                        fontFamily: display_font,
                        // fontSize: '',
                        // boxShadow: 'black -683px 0px 0px',
                        // boxShadow: '#0000001a -683px 0px 0px',
                        boxShadow: '#f7f7f8 -683px 0px 0px',
                        ...other?.second_header
                    },
                    '& .MuiTypography-h3': {
                        fontFamily: display_font,
                    },
                    '& .MuiTypography-h4': {
                        fontFamily: display_font,
                    },
                    'ul': {
                        pb: '0px',
                        pl: '1.6em',
                    },
                    'ol li::before': {
                        lineHeight: {
                            // xs: '1.7',
                            // sm: '1.6'
                        },
                        // marginTop: '-0.1em'
                    },
                    '& .ArticleBody': {
                        pb: '100px'
                    },
                    '& li': {
                        pb: '0px !important'
                    },
                    '& b': {
                        fontVariationSettings: '"wght" 600',
                    },
                    'i': {
                        fontSize: '1.05em',
                        lineHeight: '0',
                        fontVariationSettings: '"wght" 300',
                    }
                }}
            >
                { Body }
            </Box>
        </Box>
    )
}
